<template>
  <!-- v-if="hasPermission('admin_system')" -->

  <div>
  

   
    <b-col cols="12">

    <b-card  v-if="this.authUser().type == 5"
    ></b-card>
        
      <b-card v-if="this.authUser().type != 5">
        <h4 class="mb-2"> <span>
          <feather-icon icon="FileTextIcon" />
        </span> My Pending Cases</h4>


        <DataTable :total="total" style="font-size:12px;" :filter="filter" :loading="load" @reset="filter = { export: 0, removed: 0 }" @Refresh="refresh">
          <template v-if="lookups" #filter>
      
          </template>
         
          <template #customTable>
            <b-table :sort-desc.sync="filter.sort_direction" :items="items" responsive striped :fields="newFields" primary-key="id" show-empty :empty-text="$t('Global.empty_text')"  style="font-size:11px; max-height: 300px;">
            
             <template #cell(id)="data">
                <b-link 
                style="font-weight:bold ; font-size:12px; color:#054978;"
      
                :to="{ name: 'case-show', params: { id: data.item.id } }">
                  {{data.item.id}}
                  </b-link>
              </template>

              <template #cell(action)="data">
                <b-link 
             
      
                :to="{ name: 'case-show', params: { id: data.item.id } }">
                <feather-icon icon="EyeIcon" />
                  </b-link>
              </template>
            
              <template #cell(name)="data">
                <b-media vertical-align="center" class="d-flex align-items-center">
                  <template #aside>
                  </template>
                    {{ data.item ? data.item.name : '_' }}
                 
                </b-media>
              </template>
      
              
              <template #cell(status)="data">
                <b-badge pill  :variant="`light-${getStatus(data.item.status.status).color}`" class="text-capitalize">
                  {{ data.item.status.name }}
      
               
                </b-badge>
              </template>
                 <template #cell(assign_date)="data">
                {{ data.item.assign_date}}
               
              </template>
              <template #cell(type)="data">
                <span class="text-capitalize">
                  {{ data.item.type ? data.item.type.name : '-' }}
                </span>
              </template>
              <template #cell(actions)="data">
                <div class="align-items-center">
      
                  <div>
                    <feather-icon v-b-tooltip.hover="$t('Global.edit')" icon="EditIcon" style="cursor: pointer" @click="open(data.item.id)" />
                    <feather-icon v-b-tooltip.hover="$t('Global.delete')" icon="Trash2Icon" style="cursor: pointer" class="text-danger" v-if="data.item.removed == 0" @click="remove(data.item.id)" />
                    <feather-icon v-b-tooltip.hover="$t('Global.restore')" icon="RepeatIcon" style="cursor: pointer" v-else @click="restore(data.item.id)" />
                
                  </div>
                  </div>
              </template>
          </b-table>
          
          </template>
          <template #action>
         
          </template>
        </DataTable>
         
  <!-- <a href="https://magrabi.test.vero-cloud.com/api/public/pdf">
  <b-button   class="mx-2">  Generate Automatic Report</b-button></a> -->

      <!-- <a :href='`${baseUrl}/manual/pdf/75/1/2023-03-05`'>
  <b-button class="mx-2" >  Generate Manual Report</b-button></a>    
  <a :href='`${baseUrl}/pdf/75/1/2023-03-05`'>
  <b-button >  Generate Auto Report</b-button></a> -->
      
     </b-card>
     </b-col>


      <b-col cols="12" v-if="hasPermission('admin_system')">
        
        <b-card>
       
       
      
          <div class="d-flex" >
            <h4>System Activities</h4>
          </div>
          <chartjs-component-line-chart :options="options" :height="310" :data="ActivitiesData" v-if="activities" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody,
  BTable,
   BCol, BRow, BImg, BButton, BMedia, BMediaAside, BAvatar, BMediaBody, BBadge, BLink } from 'bootstrap-vue';
import Patients from "@/views/patients/components/patients";
     import Cases from "@/views/cases/components/cases";
import pieChartCategory from '@/views/dashboard/component/pieChart.vue';
import ChartjsComponentBarChart from '@/views/components/charts-components/ChartjsComponentBarChart.vue';
import ChartjsComponentLineChart from '@/views/components/charts-components/ChartjsComponentLineChart.vue';
import Statistics from '@/views/components/info/statistics';
import DataTable from '@/views/components/table/DataTable';

import { mapGetters } from 'vuex';

import { $themeColors } from '@themeConfig';
// colors
const chartColors = {
  mainColor: '#054978',
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#746D69',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
  second_color: '#f57f21',
};

export default {
  components: {
    Cases,
    DataTable,
    BTable,
    Patients,
    BCard,
    BCol,
    BBadge,
    BLink,
    BImg,
    BRow,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    Statistics,
    ChartjsComponentBarChart,
    pieChartCategory,
    ChartjsComponentLineChart,
  },
  data() {
    return {
           baseUrl: process.env.VUE_APP_BASE_URL,
      filter: { removed: 0 },
      itemss:[],
      data: null,
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 1,

                fontColor: chartColors.labelColor,
              },
            },
          ],
        },
      },
    };
  },
  computed: {

      newFields() {
      let newFields = [
          {
          key: 'id',
          label: 'Case ID',
          sortable: true,
        },
        {
             key: 'medical_record',
          label: ' MRN',
          sortable: true,
        },
          {
          key: 'status',
          label: 'status',
          sortable: true,
        },
       
         {
          key: 'branch.name',
          label: 'Branch',
          sortable: true,
        },
        // {
        //   key: 'branch.name',
        //   label: 'update',
        //   sortable: true,
        // },
        
        
          {
          key: 'action',
          label: 'Actions',
          sortable: true,
        },
      
        

         
 
      ];


      return newFields;
}
    ,
    ...mapGetters({
      counts: 'dashboard/counts',
      faculty: 'dashboard/faculty',
      activities: 'dashboard/activities',
      payment: 'dashboard/payment',
      items: 'cases/items',
      load: 'cases/load',
      total: 'cases/total',
      lookups: 'cases/lookups',
    }),
    paymentData() {
      if (this.payment) {
        return {
          labels:  Array.from(this.payment  , el=> el.label),
          datasets: [
            {
              data: Array.from(this.payment  , el=> (el.total / 1000)),
              backgroundColor: chartColors.mainColor,
              borderColor: 'transparent',
            },
          ],
        };
      }
    },
    ActivitiesData() {
      if (this.activities) {
        return {
          labels:  Array.from(this.activities  , el=> el.label),
          datasets: [
            {
              data: Array.from(this.activities  , el=> el.count),
              backgroundColor: chartColors.second_color,
              borderColor: 'transparent',
            },
          ],
        };
      }
    },
    programs() {
      if (this.faculty && this.faculty.programs_per_faculty) {
        return {
          labels:  Array.from(this.faculty.programs_per_faculty  , el=> el.name),
          datasets: [
            {
              data: Array.from(this.faculty.programs_per_faculty  , el=> el.programs_count),
              backgroundColor: chartColors.second_color,
              borderColor: 'transparent',
            },
          ],
        };
      }
    },

    courses() {
      if (this.faculty && this.faculty.courses_per_faculty) {
        return {
          labels:  Array.from(this.faculty.courses_per_faculty  , el=> el.name),
          datasets: [
            {
              data: Array.from(this.faculty.courses_per_faculty  , el=> el.courses_count),
              backgroundColor: chartColors.second_color,
              borderColor: 'transparent',
            },
          ],
        };
      }
    },

  },
  methods: {
    getStatistic() {
      // this.$store.dispatch('dashboard/counts');
      // this.$store.dispatch('dashboard/faculty');
  
      this.$store.dispatch('dashboard/Activities');
      // this.$store.dispatch('dashboard/paymentPerMonth');
    },
    refresh(query) {
      query = { ...query, status_id: 0};

this.$store.dispatch('cases/cases', { query });
     
    },
  },
  mounted() {
    this.getStatistic();
    this.refresh();
  },
};
</script>
